import React from "react"

import Layout from '../components/layout';
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import FreeTrial from "../components/free-trial";
import SEO from "../components/seo";

import LeftFeature from '../components/left-feature';
import RightFeature from '../components/right-feature';
import FeaturePararaph from '../components/feature-paragraph'
import {LaganEngineeringTestimonial} from '../components/testimonial';
import AppStoreIcons from '../components/app-store-icons';

import FreeTrialButton from '../components/free-trial-button';

import clockInSrc from '../images/clockin.png';
import timesheetSrc from '../images/my-timesheet.png'
import jobSchedulerSrc from '../images/job-scheduler.svg';
import leaveRequestSrc from '../images/leave-request.png';
import multiDeviceSrc from '../images/multi-device.png';

import factorySrc from '../images/factory.svg';

const ManufacturingTimesheetSoftware = () => (
  <Layout>
    <SEO 
    	title="Timesheet Software for Busy Manufacturing Companies"
    	description="Timesheet software for manufacturing companies. Keep track of profitability on your jobs."
    	 />
    <Navbar />
    <div className="bg-gray-50">
		  <div className="relative bg-white overflow-hidden">
			  <div className="relative pt-6 pb-16 md:pb-20 lg:pb-24 xl:pb-32">
			    <div className="mt-8 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-20 xl:mt-24">
			      <div className="lg:grid lg:grid-cols-12 lg:gap-8">
			        <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
			          <h2 className="mt-1 text-3xl tracking-tight leading-10 font-extrabold text-gray-900 sm:leading-none sm:text-6xl lg:text-5xl xl:text-5xl">
			            Timesheet software for
			            <br />
			            <span className="text-highlight-600">busy manufacturing</span> businesses
			          </h2>
			          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
			            Go digital with your employee time and attendance to ensure the jobs in your manufacturing business are running efficiently and on time.
			          </p>

			          <FreeTrialButton />
			        </div>
			        <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
			          <div className="relative mx-auto w-full rounded-lg lg:max-w-md">
			            <button className="relative block w-full rounded-lg overflow-hidden focus:outline-none focus:shadow-outline">
			              <img className="w-full" src={factorySrc} alt="Construction Workers on Site" />
			            </button>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
			<div className="py-8 bg-gray-50 overflow-hidden lg:py-24">
			  	<div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
					<LeftFeature 
				    	sectionTitle="Job Timesheets" 
				    	title="Track Time on Jobs" 
				    	body={
				    		<div>
					    		<FeaturePararaph>
						          With TimeKeeper, employees can select a job when clocking in to log time spent on that job.
						        </FeaturePararaph>
						        <FeaturePararaph>
						          Each clock in is verified by snapping a picture, logging the time and performing facial recognition so each clock in is verified to eliminate buddy clock ins.
						        </FeaturePararaph>
						    </div>
				    	}
				    	imgSrc={clockInSrc}
				    	imgAlt="Showing Man Using Facial Recognition On Clock In">
				    </LeftFeature>
				    <RightFeature 
				    	sectionTitle="Job Efficiency + Productivity" 
				    	title="Visibility into your job takt time" 
				    	body={
				    		<div>
					    		<FeaturePararaph>
						          The problem with manual or paper timesheets is that it's easy to record information, but hard to glean insights from those records.
						        </FeaturePararaph>
						        <FeaturePararaph>
						          With TimeKeeper, as everything is structured for you we can give you instant reports on how much time is being spent on jobs, by who and when. Allowing you to guarantee that you are making money on your jobs.
						        </FeaturePararaph>
						    </div>
				    	}
				    	imgSrc={timesheetSrc}
				    	imgAlt="TimeKeeper Timesheets"
				    />
				    <LeftFeature 
				    	sectionTitle="Flexible clock in options" 
				    	title="Kiosk, Mobile and Web Clock In" 
				    	body={
				    		<div>
					    		<FeaturePararaph>
						          If you'd like one device for multiple employees to clock in from, you can use TimeKeeper Kiosk. Kiosk is typically run on a tablet fixed with a wall mount to an area in the factory.
						        </FeaturePararaph>
						        <FeaturePararaph>
						          Alternatively, employees can be enabled to use their individual mobile phones or web to clock in and out.
						        </FeaturePararaph>
						        <AppStoreIcons />
						    </div>
				    	}
				    	imgSrc={multiDeviceSrc}
				    	imgAlt="Multiple Device"
				    	 />
				    <RightFeature 
				    	sectionTitle="Staff Leave Management" 
				    	title="Manage Employee Time Off" 
				    	body={
				    		<div>
					    		<FeaturePararaph>
						          Staff need time off here and there, why make it harder by using manual records? You may be tired of staff asking you how many holidays they have left or sick days they have used?
						        </FeaturePararaph>
						        <FeaturePararaph>
						          TimeKeeper manages your staff leave for you. Our app allows your employees to request their holidays which require approval from their line manager. The line manager can approve or decline this with the touch of a button.
						        </FeaturePararaph>
						    </div>
				    	}
				    	imgSrc={leaveRequestSrc}
				    	imgAlt="Request annual leave for an employee"
				    />
				    <LeftFeature 
				    	sectionTitle="Keep your employees on the right job" 
				    	title="Integrated job scheduler" 
				    	body={
				    		<div>
					    		<FeaturePararaph>
						        	Get rid of that production board that's constantly being updated. Use our integrated web job scheduler to plan your jobs for your employees.
						        </FeaturePararaph>
						        <FeaturePararaph>
						        	We'll automatically notify your employees via a push notification of changes to their schedule.
						        </FeaturePararaph>
						    </div>
				    	}
				    	imgSrc={jobSchedulerSrc}
				    	imgAlt="Schedule Jobs"
				    />
				</div>
				<LaganEngineeringTestimonial />
				<FreeTrial includeDemo={true} part1={<span>Ready to save your <span className="text-highlight-600">manufacturing</span> <br /> business thousands?</span>} />
			</div>
		</div>
		<Footer/>
  </Layout>
)

export default ManufacturingTimesheetSoftware;
